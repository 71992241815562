import request from '@/util/request.js'

export function getSolutionDetail(id) {
  return request({
    url: '/json/program/detail/' + id,
    method: 'get'
  })
}
export function getDetailList() {
  return request({
    url: '/json/program/list',
    method: 'post'
  })
}
export function getListCover() {
  return request({
    url: '/json/program/listCover',
    method: 'post'
  })
}
