import request from '@/util/request.js'
// 关于信息
export function aboutInfo(data) {
  return request({
    url: '/json/aboutInfo/list',
    method: 'post',
    data
  })
}
// 招聘信息列表
export function jobOffersList(data) {
  return request({
    url: '/json/jobOffers/list',
    method: 'post',
    data
  })
}
// 招聘详细信息
export function jobOffersDetail(id) {
  return request({
    url: '/json/jobOffers/detail/' + id,
    method: 'get'
  })
}