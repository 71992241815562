import request from '@/util/request.js'
// 首页轮播图列表
export function bannerList(data) {
  return request({
    url: '/json/carousel/list',
    method: 'post',
    data
  })
}
// 首页轮播图详细信息
export function bannerDetail(id) {
  return request({
    url: '/json/carousel/detail/' + id,
    method: 'get'
  })
}
