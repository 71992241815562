<template>
  <div class="index">
    <el-carousel height="607px" class="banner">
      <el-carousel-item v-for="item in imglist" :key="item.id">
        <img :src="item.photo" alt="" style="width:100%" @click="bannerDetail(item)" />
      </el-carousel-item>
    </el-carousel>
    <div class="intro">
      <div class="intro-img">
        <img src="../../assets/ourservice.png" alt="" />
      </div>
      <div class="intro-cont">
        <h2>西安港湾软件科技有限公司</h2>
        <div v-for="item in contList" :key="item.id" class="item" @click="goServe(item.id)">
          <p class="intro-cont-title">{{ item.serveName }}</p>
          <p class="intro-cont-desc">{{ item.serveIntro }}</p>
        </div>
      </div>
    </div>
    <div class="solution">
      <div class="sol-box">
        <div class="s-left">
          <div class="s-left-title"></div>
          <div class="s-left-info">
            <div class="s-left-cont">
              <p v-if="solutionShow.programIntr">{{ solutionShow.programIntr }}</p>
              <div class="s-btn" @click="goSolutionDetail(solutionShow.id)">查看详情</div>
            </div>
          </div>
        </div>
        <div class="s-right">
          <div class="s-right-list">
            <div class="list">
              <div class="left">
                <div @click="showInfo(solutionList[0])">
                  <img :src="solutionList[0].programId" alt="" />
                  <p>{{ solutionList[0].schemeName }}</p>
                </div>
                <div @click="showInfo(solutionList[1])">
                  <img :src="solutionList[1].programId" alt="" />
                  <p>{{ solutionList[1].schemeName }}</p>
                </div>
              </div>
              <div class="right">
                <div @click="showInfo(solutionList[2])">
                  <img :src="solutionList[2].programId" alt="" />
                  <p>{{ solutionList[2].schemeName }}</p>
                </div>
                <div @click="showInfo(solutionList[3])">
                  <img :src="solutionList[3].programId" alt="" />
                  <p>{{ solutionList[3].schemeName }}</p>
                </div>
              </div>
            </div>
            <div class="more" @click="$router.push('/scheme')">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div ref="about" class="about">
      <div class="about_box">
        <div class="about-bg">
          <img src="../../assets/about.png" alt="" />
        </div>
        <div class="about-main">
          <div class="about-words" v-html="aboutInfo.aboutContent"></div>
          <div class="about_bottom">
            <img src="../../assets/job.png" alt="" />
            <div class="center_box">
              <div v-for="(item,index) in typeList" :key="index" class="item" :style="{backgroundColor:item.bg}" :class="item.id?'hand':''" @click="goDetail(item.id)">
                <img :src="item.recIcon" alt="" />
                <p>{{ item.recruitment }}</p>
              </div>
            </div>
            <div class="about-info">
              <p>
                <img src="../../assets/phone.png" alt="" />
                <span>{{ aboutInfo.telephone }}</span>
              </p>
              <!--              <p>-->
              <!--                <img src="../../assets/qq.png" alt="" />-->
              <!--                <span>{{ aboutInfo.aboutQq }}</span>-->
              <!--              </p>-->
              <p>
                <img src="../../assets/email.png" alt="" />
                <span>{{ aboutInfo.mail }}</span>
              </p>
              <p>
                <img src="../../assets/LOCATION.png" alt="" />
                <span>{{ aboutInfo.address }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="bannerContent.name"
      :visible.sync="DialogVisible"
      width="70%"
      center>
      <p v-html="bannerContent.content"></p>
    </el-dialog>

  </div>
</template>
<script>
import {bannerList, bannerDetail} from '@/api/index/index.js'
import {serveList} from '@/api/serve/serve.js'
import {aboutInfo, jobOffersList} from '@/api/about/about.js'
import {getDetailList} from '@/api/scheme/scheme.js'
export default {
  data() {
    return {
      DialogVisible: false,
      imglist: [],
      bannerContent: {},
      contList: [],
      solutionList: [],
      // 招聘信息列表
      typeList: [],
      aboutInfo: {},
      solutionShow: {}
    }
  },
  mounted() {
    // 轮播图
    bannerList().then(res => {
      this.imglist = res.data.data
    })
    // 服务列表
    serveList().then(res => {
      this.contList = res.data.data
    })
    // 首页解决方案列表
    getDetailList().then(res => {
      console.log(res)
      this.solutionList = res.data.data.length > 4 ? res.data.data.slice(0, 4) : res.data.data
      this.solutionShow = this.solutionList[0]
      console.log(this.solutionList)
    })
    // 首页关于信息
    aboutInfo().then(res => {
      this.aboutInfo = res.data.data[0]
    })
    // 招聘信息列表
    jobOffersList().then(res => {
      for (let i = 0; i < 9; i++) {
        this.typeList.push({
          bg: ''
        })
      }
      this.typeList[0] = res.data.data[0]
      this.typeList[0].bg = '#f6a87d'
      this.typeList[2] = res.data.data[1]
      this.typeList[2].bg = '#04bbb9'
      this.typeList[4] = res.data.data[2]
      this.typeList[4].bg = '#212c53'
      this.typeList[6] = res.data.data[3]
      this.typeList[6].bg = '#b57bf1'
      this.typeList[8] = res.data.data[4]
      this.typeList[8].bg = '#f699cc'
    })
    if (this.$route.query.invite === 'true') {
      const target = this.child().offsetTop
      document.documentElement.scrollTop = target
    }
  },
  methods: {
    showInfo(item) {
      this.solutionShow = item
    },
    child() {
      const dom = this.$refs.about
      return dom
    },
    goDetail(id) {
      console.log(id)
      if (id) {
        this.$router.push({
          path: '/about',
          query: {id}
        })
      }
    },
    bannerDetail(item) {
      if (item.url) {
        window.open(item.url)
      } else {
        bannerDetail(item.id).then(res => {
          this.bannerContent = res.data.data
          this.DialogVisible = true
        })
      }
    },
    goServe(id) {
      this.$router.push({
        path: '/serve',
        query: {id}
      })
    },
    goSolutionDetail(id) {
      this.$router.push({
        path: '/detail',
        query: {id}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.index{
  // .banner{
  //   width: 100%;
  //   transform: translateX(50%);
  //   margin-left: -50%;
  // }
  .intro{
    margin: 55px auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .intro-img{
      img{
        width: 350px;
        height: 781px;
      }
    }
    .intro-cont{
     margin-left: 113px;
     h2{
      color: #000000;
       font-size:48px;
       font-weight: 400;
       margin-bottom: 65px;
     }
     .item{
       margin-bottom:42px;
       cursor:pointer;
       .intro-cont-title{
          margin-bottom: 16px;
          font-size: 26px;
          display: inline-block;
          background: -webkit-linear-gradient(left,#3887e2 25%,#f9290a 70%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .intro-cont-desc{
        }
     }
    }
  }
  .solution{
    // background-image: url('../../assets/solution.png');
    background-image: url('../../assets/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .sol-box{
      width: 1200px;
      height: 1085px;
      margin: 0 auto;
      display: flex;
      // border: 1px solid red;
      .s-left {
         width:calc(100% - 330px);
          // border: 1px solid blue;
          padding: 177px 0 127px 0;
        .s-left-title{
          height: 71px;
          background: url(../../assets/SOLUTION_1.png) no-repeat;
          margin-bottom: 100px;
        }
        .s-left-info{
          height: 528px;
          // width: 870px;
          background-image: url('../../assets/mac.png');
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 100% 100%;
          // position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
           .s-left-cont{
              background-color: #61658b;
              height:355px;
              width:calc(73% - 56px);
              padding: 24px 32px 24px 24px;
              color: white;
              position: relative;
              top:-17px;
              cursor: pointer;
              .s-btn{
                 width: 120px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  background-color: #3787e2;
                  color: white;
                   position: absolute;
                  left: 50%;
                  bottom:24px;
                  transform: translateX(-50%);
                  border-radius: 26px;
              }
           }
        }
      }
      .s-right{
        width: 330px;
        // border: 1px solid green;
        padding:120px 0 100px;
        height:calc(100% - 220px);
        .s-right-list{
          background-image: url('../../assets/solution2.png');
          padding-top: 200px;
          background-repeat: no-repeat;
          height: calc(100% - 200px);
          .list{
            width: 100%;
              height:60%;
              display: flex;
              justify-content:space-between;
                .left,.right{
                   width: 150px;
                   height:100%;
                  >div{
                    position: relative;
                    width: 100%;
                    height: 150px;
                    overflow: hidden;
                    img{
                        transition: all 1s linear;
                        //   transform: scale(1);
                        //   position: absolute;
                          width: 100%;
                          height: 100%;
                        //   z-index: -1;
                        //   left: 0;
                    }
                    img:hover{
                        transition:all 1s linear;
                          transform: scale(1.2);
                          // position: absolute;
                          // width: 100%;
                          // height: 100%;
                          // z-index: -1;
                          // left: 0;
                    }
                    p{
                      width: 100%;
                        height: 40px;
                        background-color: #dddddd;
                        opacity: .7;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        line-height: 40px;
                        font-size: 18px;
                        font-weight: bold;
                        color: #000000;
                        overflow: hidden;
                    }
                  }
                  >div:nth-child(1){
                    margin-bottom: 26px;
                  }
                }
                .right{
                  position: relative;
                  top: -50px;
                }
          }
          .more{
            // position: absolute;
            width: 170px;
            height: 50px;
            background-color: #3787e2;
            color: white;
            font-size: 18px;
            line-height: 50px;
            text-align: center;
            border-radius: 25px;
            cursor: pointer;
            margin-top:70px;
            margin-left:calc(100% - 170px);
          }

        }
      }
    }
  }
  .about{
    width: 100%;
    background-image: url('../../assets/about_bjgbg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .about_box{
      width: 1325px;
      // height: 900px;
      margin: 0 auto;
      // border: 1px solid red;
      .about-bg{
        text-align: center;
        //  border: 1px solid blue;
      }
      .about-main{
        min-height:688px;
        margin-top: 50px;
        background-image: url('../../assets/about_bg.png');
        position: relative;
        padding: 31px 40px 0 40px;
        color: #fff;
        .about-words{
          text-indent: 2em;
          line-height:3;
        }
        .about_bottom{
           display: flex;
           position:absolute;
           bottom: 40px;
          //  border: 1px solid red;
           >img{
             width: 265px;
             height: 242px;
             position:relative;
             top: 70px;
           }
           .center_box{
             width:300px;
             height: 300px;
             display: flex;
             flex-wrap: wrap;
             margin-left: 40px;
             .item{
                width: 100px;
                height: 100px;
                 display: flex;
                 flex-direction: column;
                 justify-content: center;
                 align-items: center;
               img{
                  height: 50px;
                  margin: 0 auto 10px;
               }
               p{
                 font-size: 14px;
               }
             }
             .hand{
               cursor: pointer;
             }
           }
           .about-info{
             width: 525px;
             margin-left:150px;
             font-size: 24px;
             p{
               margin-top: 25px;
               display: flex;
               align-items: center;
               img{
                 margin-right:35px;
               }
             }
             >p:nth-child(1){
               margin-top: 0px;
             }
           }
        }
      }
    }
  }

}
</style>
